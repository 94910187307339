/* eslint-disable max-lines */
import { BroadcastChannel } from 'types/broadcastChannels';

const enTranslations = {
  languages: {
    fr: 'French',
    en: 'English',
    it: 'Italian',
    br: 'Breton',
    ar: 'Arabic',
  },
  alert: {
    number: 'Alert number',
    'creation-date': 'Creation date',
    'created-by': 'Alert creator',
    cause: {
      label: 'Cause',
      ACCIDENT: 'ACCIDENT',
      TECHNICAL_PROBLEM: 'TECHNICAL PROBLEM',
      MAINTENANCE: 'MAINTENANCE',
      CONSTRUCTION: 'CONSTRUCTION',
      STRIKE: 'STRIKE',
      DEMONSTRATION: 'DEMONSTRATION',
      WEATHER: 'WEATHER',
      HOLIDAY: 'HOLIDAY',
      POLICE_ACTIVITY: 'POLICE ACTIVITY',
      MEDICAL_EMERGENCY: 'MEDICAL EMERGENCY',
      OTHER_CAUSE: 'OTHER CAUSE',
      UNKNOWN_CAUSE: 'UNKNOWN CAUSE',
    },
    effect: {
      label: 'Impact',
      NO_SERVICE: 'NO SERVICE',
      SIGNIFICANT_DELAYS: 'SIGNIFICANT DELAYS',
      DETOUR: 'DETOUR',
      REDUCED_SERVICE: 'REDUCED SERVICE',
      STOP_MOVED: 'STOP MOVED',
      MODIFIED_SERVICE: 'MODIFIED SERVICE',
      ADDITIONAL_SERVICE: 'ADDITIONAL SERVICE',
      OTHER_EFFECT: 'OTHER EFFECT',
      UNKNOWN_EFFECT: 'UNKNOWN EFFECT',
    },
    'import-type': {
      new: 'new',
      update: 'update',
      closure: 'closure',
      no_change: 'no change',
      problem: 'problem',
      processing: 'processing',
      ready_to_import: 'ready to import',
    },
    'start-date': 'Disruption start',
    'end-date': 'Disruption end',
    'route-type': {
      '0': 'Tramway',
      '1': 'Metro',
      '2': 'Train',
      '3': 'Bus',
      '4': 'Ferry',
      '5': 'Tramway à traction par câble',
      '6': 'Télécabine ou téléphérique',
      '7': 'Funiculaire',
      '100': 'Service de chemin de fer',
      '101': 'Service de chemin de fer à grande vitesse',
      '102': 'Grandes lignes de chemin de fer',
      '103': 'Service de chemin de fer transrégional',
      '104': 'Service de transport de voitures par chemin de fer',
      '105': 'Service de chemin de fer à couchettes',
      '106': 'Service de chemin de fer régional',
      '107': 'Service de chemin de fer touristique',
      '108': "Navette ferroviaire (au sein d'un complexe)",
      '109': 'Chemin de fer de banlieue',
      '110': 'Service de chemin de fer de substitution',
      '111': 'Service de chemin de fer spécial',
      '112': 'Service de transport de camions par chemin de fer',
      '113': 'Service de chemin de fer (toute catégorie)',
      '114': 'Service de chemin de fer transnational',
      '115': 'Service de transport de véhicules par chemin de fer',
      '116': 'Chemin de fer à crémaillère',
      '117': 'Service de chemin de fer supplémentaire',
      '200': 'Service de cars',
      '201': 'Service de cars internationaux',
      '202': 'Service de cars nationaux',
      '203': 'Service de navette par cars',
      '204': 'Service de cars régionaux',
      '205': 'Service de cars spéciaux',
      '206': 'Service de visite touristique en car',
      '207': 'Service de cars touristiques',
      '208': 'Service de cars de banlieue',
      '209': 'Service de cars (toute catégorie)',
      '400': 'Service de chemin de fer urbain',
      '401': 'Service de chemin de fer métropolitain',
      '402': 'Service de transport souterrain',
      '403': 'Service de chemin de fer urbain',
      '404': 'Service de chemin de fer urbain (toute catégorie)',
      '405': 'Monorail',
      '700': "Service d'autobus",
      '701': "Service d'autobus régional",
      '702': "Service d'autobus express",
      '703': "Service d'autobus desservant des arrêts",
      '704': "Service d'autobus local",
      '705': "Service d'autobus nocturne",
      '706': 'Service de car postal',
      '707': "Service d'autobus pour passagers présentant des besoins spéciaux",
      '708': "Service d'autobus pour personnes à mobilité réduite",
      '709': "Service d'autobus pour personnes à mobilité réduite (déclarées comme telles)",
      '710': 'Autobus panoramique',
      '711': 'Service de navette par autobus',
      '712': 'Bus scolaire',
      '713': 'Bus scolaire et à usage public',
      '714': "Service de substitution par autobus (remplacement d'un service de chemin de fer)",
      '715': 'Autobus à la demande',
      '716': "Service d'autobus (toute catégorie)",
      '717': 'Service de taxi partagé',
      '800': 'Service de trolleybus',
      '900': 'Service de tramway',
      '901': 'Service de tramway urbain',
      '902': 'Service de tramway local',
      '903': 'Service de tramway régional',
      '904': 'Service de tramway touristique',
      '905': 'Service de navette par tramway',
      '906': 'Service de tramway (toute catégorie)',
      '907': 'Tramway à traction par câble',
      '1000': 'Service de transport fluvial',
      '1100': 'Service de transport aérien',
      '1200': 'Service de ferry',
      '1300': 'Service de téléphérique',
      '1400': 'Service de funiculaire',
      '1500': 'Service de taxis',
      '1501': 'Service de taxis communaux',
      '1502': 'Service de taxi fluvial',
      '1503': 'Service de taxis en gare de chemin de fer',
      '1504': 'Service de vélotaxi',
      '1505': 'Service de taxis réglementé',
      '1506': 'Service de location de véhicules particuliers',
      '1507': 'Service de taxis (toute catégorie)',
      '1700': 'Services divers',
    },
    channel: {
      EMAIL: 'Email Campaign',
      SMS: 'SMS Campaign',
      TRANSACTIONAL_SMS: 'SMS',
      TRANSACTIONAL_EMAIL: 'Email',
      GTFS_RT: 'Website + Mobile App + Google Maps',
      TWITTER: 'X/Twitter',
      PUSH: 'Push notification',
      WEBSITE_BANNER: 'Website Banner',
    },
    channelContact: {
      TRANSACTIONAL_SMS: 'text message',
      TRANSACTIONAL_SMS_PLURAL: 'text messages',
      TRANSACTIONAL_EMAIL: 'email',
      TRANSACTIONAL_EMAIL_PLURAL: 'emails',
    },
    broadcastStatus: {
      DRAFT: 'Draft',
      ON_GOING: 'On going',
      PROGRAMMED: 'To come',
      SENT: 'Sent',
      SKIPPED: 'Skipped',
      ERROR: 'Problem',
      CANCELED: 'Canceled',
    },
    broadcastStatusTooltip: {
      SKIPPED: {
        TWITTER:
          'This update has not been broadcasted on Twitter because the content on this channel was the same as the previous broadcast',
        TRANSACTIONAL_SMS:
          'This update has not been broadcasted via SMS since no one is subscribed to this channel',
        TRANSACTIONAL_EMAIL:
          'This update has not been broadcasted via Email since no one is subscribed to this channel',
      },
    },
  },
  component: {
    header: {
      title: 'DAIT',
    },
    'alert-information-overview': {
      title: 'Alert information',
    },
    'alert-routes-overview': {
      title: 'Impacted lines',
    },
    'routes-selection': {
      'select-all': 'Select all',
      title: 'Lines',
      error: {
        required: 'Please fill in at least one line',
      },
      'see-routes': 'See lines',
      'select-routes': '{routeType} – Select the concerned lines',
      validate: 'Validate',
      'route-types': 'Transportation modes',
    },
    'alert-routes-card': {
      'no-route-selected': 'No line selected',
    },
    'alert-template-form': {
      'template-name': {
        title: 'Template name',
        name: {
          label: 'Name',
          error: {
            EMPTY_NAME: 'Please enter a name',
            INVALID_NAME: 'Pease enter a different name',
          },
        },
      },
      'alert-information': {
        title: 'Alert information',
        cause: {
          placeholder: 'Cause',
          label: 'Cause',
        },
        effect: {
          placeholder: 'Impact',
          label: 'Impact',
        },
      },
      channels: {
        title: 'Communication channels',
      },
    },
    'channels-configuration': {
      'delete-language': 'Delete',
      'confirm-deletion': 'Confirm',
      'cancel-deletion': 'Cancel',
      'confirm-deletion-text': 'Are you sure you want to delete this language?',
      'add-language': 'Add a new broadcast language',
      'alert-content': {
        'title-first': 'Alert content',
        'title-other': 'Alert content (language {index})',
        'translation-label': 'Automatically generate a translation',
        'translation-warning':
          'Warning! Automatic translations can sometimes contain errors. It is therefore important to check the content before broadcasting your alert.',
        'translation-error':
          'The automatic translation service is unavailable. Please try again later, or use the alternative translation solutions.',
        'translation-truncated':
          'The suggested translation exceeds the permitted number of characters. It is therefore important to check the content before broadcasting your alert.',
      },
      enrich: '{channel} Channel – Enrich',
      language: {
        label: 'Broadcast language',
        error: {
          required: 'Please add a broadcast language for this alert',
        },
      },
      save: 'Save',
      object: {
        label: 'subject',
        help: ' This field will be used as the subject of the email, title of the push notification, and title of the alert broadcast on the website, the mobile application and the GTFS-RT stream.',
        error: {
          required: 'Please enter a subject for this alert',
          'max-length': 'Please enter a subject of less than 6 characters',
        },
      },
      [BroadcastChannel.WEBSITE_BANNER]: {
        label: 'Website Banner Content ({language})',
        note: 'Configure and create a banner on your website.',
        bannerTitle: {
          label: 'Banner title',
          description: 'Message visible on the information banner',
        },
        pageTitle: {
          label: 'Page title',
          description: 'Title of the page associated with the information banner',
        },
        bannerContent: {
          label: 'Description',
          description: 'Content of the page associated with the information banner',
        },
      },
      [BroadcastChannel.TRANSACTIONAL_EMAIL]: {
        label: 'Email content ({language})',
        note: 'Edit content and set up a test email, receive your alert as it will be seen by your users.',
        test: {
          error: 'Failed!',
          errorDetails: 'The email has not been sent. Please try again.',
          sending: 'Sending...',
          sendTest: 'Receive a test email',
          sent: 'Sent!',
          sentDetails: 'Your test email is available in your inbox',
        },
      },
      'short-description': {
        label: 'Short description (SMS, Push notifications, X/Twitter)',
        error: {
          required: 'Please enter the content of your alert',
          'max-length': ' Please enter a short description of less than 152 characters',
        },
      },
      'long-description': {
        label: 'Long description (Website + Mobile App + Google Maps, E-mail, Website banner page)',
        error: {
          required: 'Please enter the content of your alert',
          'max-length': 'Please enter a long description of less than 1200 characters ',
        },
        note: 'Please note that you can format the content of your alert on the email channel. To do so, select the email channel in the communication channels below and click on the edit icon on the right of the table.',
      },
      'twitter-enrich-subtext':
        'Enrich your Twitter posts by creating connected posts to update the alert or by adding images to the alert.',
      'twitter-history-title': 'Tweets History',
      'twitter-history-empty': 'No tweets published yet. Write a new post.',
      'twitter-description': {
        label: 'New tweet',
        error: {
          required: 'Please enter the content of your alert',
          'max-length': 'Please enter a description of less than 280 characters ',
        },
      },
      'twitter-image-description': {
        label: 'Add a description',
        note: 'Improve the accessibility and visibility of your post by adding a description to your image!',
      },
      'rich-text': {
        'link-title': 'Insert a link',
        'link-name': 'Text to display',
        'link-target': 'Link',
        insert: 'Insert',
      },
      upload: {
        title: 'Add a file or a link',
        label: 'Add a PDF file',
        button: 'Upload a file',
        copyInfo: ' The URL is copied to your clipboard.',
        copyError: ' Copy-paste did not work.',
        tip1: 'If you upload a document, it will be automatically inserted into the channel "Website + Mobile App + Google Maps".',
        tip2: 'If you want to join it to other channels, copy and paste the URL of the map into the content of the channels of your choice.',
        linkLabel: 'Add a URL link',
        linkPlaceholder: 'https://example.com',
        linkTip: 'The link will be added to the content of the alert sent to the different media.',
        tooltip:
          'Add a file or URL to your alert. This element will be available in all your media. You can only add a link OR a file.',
        'invalid-url': 'Please enter a valid URL',
      },
      uploadImage: {
        button: 'Click to add a file',
        label: 'Add an image',
        supportedFiles: 'Supported files: JPEG, JPG, PNG',
        size: 'Images are displayed at 16:9 ratio, or at 600px by 335px, in the Twitter feed. And when clicked, they are enlarged up to 1,200px by 675px.',
        sizeError: 'The uploaded file exceeds the maximum authorized size limit (5 MB).',
      },
      channels: {
        label: 'Manage your communications channels',
        'twitter-multilingual-error':
          'The message can only be broadcasted on twitter in {defaultLanguage}',
        error: {
          required: 'Please select at least one broadcast channel',
        },
        'push-error':
          'To broadcast your alert on the "Push Notification" channel, the " Website + Mobile App + Google Maps" channel must be selected.',
        empty: 'No channel available',
        enrich: 'Enrich',
        info: 'In this section you can manage and enrich the content of all the communication channels before publishing your alert. \n Click on "Enrich" to manage the content of each channel!',
        name: 'Channel',
        message: 'Message',
        'message-type': {
          SHORT: 'Short',
          LONG: 'Long',
          CUSTOM: 'Personalized',
        },
        update: 'Actions',
      },
    },
    'broadcast-date-configuration': {
      'confirm-programmation': 'Confirm',
      'cancel-programmation': 'Cancel',
      title: 'Program the broadcast of the alert',
      'broadcast-before-today': 'Please select a time and date in the future',
      'broadcast-after-end': 'Please schedule your alert prior to the end date of the disruption',
    },
  },
  page: {
    'alert-creation': {
      'template-selection': {
        'use-template': 'Use a template',
        confirm: 'Confirm',
        content: 'Steps 1 and 3 will be pre-filled with information from the model',
        select: 'Select a template',
        cancel: 'Cancel',
        errorLabel: 'Please select a template',
      },
      steps: {
        information: 'Alert information',
        routes: 'Impacted lines',
        channels: 'Communication channels',
        summary: 'Validate',
      },
      information: {
        error: {
          ALERT_ALREADY_PUBLISHED: 'Alert has already been published',
        },
      },
      'routes-selection': {
        agency: 'Agencies: ',
        error: {
          ALERT_ALREADY_PUBLISHED: 'Alert has already been published',
        },
      },
      'channels-configuration': {
        error: {
          ALERT_ALREADY_PUBLISHED: 'Alert has already been published',
          TWITTER_MESSAGE_TOO_LONG: 'The Twitter message must be less than 280 characters long',
        },
      },
      cause: {
        placeholder: 'Cause',
        label: 'Cause',
        error: {
          required: 'Please specify the cause of the alert',
        },
      },
      effect: {
        placeholder: 'Impact',
        label: 'Impact',
        error: {
          required: 'Please specify the impact of the alert',
        },
      },
      period: {
        title: 'Disruption period',
        'start-date': {
          label: 'Start date',
          error: {
            required: 'Please specify a start date for the disruption period',
          },
          help: 'This is the disruption start date only. You will be able to choose when the Alert is visible to travelers on Step 4.',
        },
        'end-date': {
          label: 'End date',
          help: "The Alert will no longer be visible to travelers on this date. It's possible to leave it empty and update or close the Alert manually at a later stage.",
          error: {
            beforeNow: 'Please specify a date later than the current date',
            beforeStartDate: 'Please specify a date later than the start date',
            incomplete: 'Please complete the end date',
          },
        },
      },
      summary: {
        object: 'Subject:',
        externalLink: 'File or URL linked:',
        broadcastLater: 'Program broadcast',
        broadcast: 'Broadcast now',
        error: {
          END_DATE_BEFORE_TODAY: 'The end date of the disruption is earlier than the current date.',
          ALERT_ALREADY_PUBLISHED: 'Alert has already been published',
          END_DATE_BEFORE_BROADCAST_DATE: 'Disruption end date is before broadcast date',
          BROADCAST_DATE_BEFORE_TODAY: 'The broadcast date is before today',
        },
        channels: {
          name: 'Channel',
          message: 'Message',
        },
      },
      next: 'Next',
    },
    'alert-detail': {
      title: 'Alert details',
      'broadcast-history': {
        title: 'Previous broadcast',
        'title-future': 'Future broadcast',
        panel: {
          header: {
            INITIAL: 'First broadcast - {date}',
            ADDITIONAL: 'Update  - {date}',
          },
          body: {
            object: 'Subject:',
            externalLink: 'File or URL linked:',
            channel: 'Channel',
            message: 'Message',
            'broadcast-status': ' Sending status',
          },
        },
      },
    },
    'alert-import': {
      title: 'File import',
      steps: {
        publish: 'Import',
        'select-a-file': 'Select a file',
      },
      publish: {
        agency: 'Agency',
        cancel: 'Cancel import',
        publish: 'Confirm import',
        confirm: {
          message: 'Are you sure you want to import these alerts for the following agencies?',
          confirm: 'Confirm',
          cancel: 'Cancel',
          'error-message': 'There has been a problem with the Import',
          help: 'Please contact your administrator or IT Support',
          successTitle: 'The alerts have been imported successfully in Notify!',
          successMessage: 'You can update or close these alerts through a new file import',
          ok: 'Ok',
        },
        effect: 'Impact',
        endDate: 'Disruption end',
        importType: 'Import status',
        location: 'File row {row}, column {column}: ',
        modal: {
          'created-alerts': '{current}/{final} alerts have been created',
          'updated-alerts': '{current}/{final} alerts have been updated',
          'closed-alerts': '{current}/{final} alerts have been closed',
          'unchanged-alerts': '{current}/{final} alerts have not been changed',
        },
        'imported-alerts': '{count} {count, plural, one {alert} other {alerts}} will be imported',
        'updated-alerts': '{count} {count, plural, one {alert} other {alerts}} will be updated',
        'closed-alerts': '{count} {count, plural, one {alert} other {alerts}} will be closed',
        'not-changed-alerts':
          '{count} {count, plural, one {alert} other {alerts}} will not be changed',
        'new-alerts': '{count} {count, plural, one {alert} other {alerts}} will be created',
        replace: 'Replace import file',
        results: 'Results',
        routes: 'Lines',
        rowNumber: 'File row',
        startDate: 'Disruption start',
        problem: 'Problem',
        problems: {
          'broadcast-date-in-past': 'The broadcast date cannot be in the past',
          'end-date-before-start-date': 'The end date must be after the start date',
          'subject-too-long': 'The subject is too long. Maximum 60 characters',
          'description-too-long': 'The description is too long. Maximum 1200 characters',
          'missing-date': 'The date or time is missing',
          'missing-description': 'The description is missing',
          'missing-effect': 'The impact is missing',
          'missing-cause': 'The cause is missing',
          'invalid-agency':
            'The agency indicated does not exist or you do not have permission to import alerts for the agency indicated',
          'invalid-date': 'The date or time format is not recognized',
          'invalid-route': 'A line does not exist in the selected Agency',
          'agency-not-selected': 'The agency is not included in the import impacted agencies',
          'missing-agency-id': 'The Agency is missing',
          'missing-subject': 'The subject is missing',
          'missing-routes': 'Line/s missing',
          'invalid-cause': 'The cause does not exist',
          'invalid-effect': 'The impact does not exist',
        },
        'problems-detected':
          '{count} {count, plural, one {problem has} other {problems have}} been detected. Unless you correct the problems on this page, these alerts will not be imported',
      },
      'select-agencies': {
        label: 'Select agencies for the import',
      },
      'select-file': {
        button: 'Select file',
        'instructions-0': 'Import alerts:',
        'instructions-1': '<b>Step 1:</b> select the Agencies to be updated by the import',
        'instructions-2': '<b>Step 2:</b> upload an xls file and correct any problems detected',
        'instructions-3': '<b>Step 3:</b> confirm the import',
      },
      upload: {
        button: 'Start import',
      },
    },
    'alert-update': {
      title: 'Alert update',
      'access-button': 'Update alert',
      'restart-button': 'Restart alert',
      'close-button': 'Close alert',
      broadcastLater: 'Program broadcast',
      broadcast: 'Broadcast now',
      'file-import': ' - File import',
      'start-date': {
        error: {
          required: 'Please specify a start date for the disruption',
        },
      },
      'end-date': {
        error: {
          incomplete: 'Please complete the end date',
          beforeStartDate: 'Please specify a date later than the start date',
          beforeCurrentDate: 'Please specify a date later than the current date',
          beforeBroadcastDate: 'Please specify a broadcast date before the end date',
        },
      },
      'close-modal': {
        error: 'There was an error closing the alert',
        confirm: 'Confirm',
        cancel: 'Cancel',
        question:
          "Are you sure you want to close the alert? It won't visible anymore on the website, the mobile application and Google Maps",
      },
      information: 'Alert information',
      routes: 'Impacted lines',
      channels: 'Communication channels',
      summary: 'Validate',
      'confirm-broadcast-question': 'Do you confirm the broadcast of this update?',
      'confirm-broadcast': 'Yes',
      'cancel-broadcast': 'No',
      error: {
        INVALID_ROUTE_ID:
          'One of the indicated lines does not exist or no longer exists in the network',
        TWITTER_MESSAGE_TOO_LONG: 'The Twitter message must be less than 280 characters long',
      },
    },
    'alert-list': {
      create: 'Create a new alert',
      createTemplate: 'Alert templates',
      'empty-list': 'No alert',
      filters: {
        future: 'Programmed alerts',
        'on-going': 'Ongoing alerts',
        completed: 'Past alerts',
        draft: 'Drafts',
      },
      importAlerts: 'Import alerts',
      'delete-draft': {
        confirm: 'Confirm',
        cancel: 'Cancel',
        'question-draft': 'Are you sure you want to delete the draft?',
        question: 'Are you sure you want to delete the alert?',
      },
      agencies: {
        label: 'Agency',
      },
      routes: {
        label: 'Line',
        network: 'All network',
        type: 'Network {type}',
      },
      title: 'Alerts table',
      number: 'N°',
      'last-broadcast': 'Last broadcast',
      'broadcast-status': 'Sending status',
      actions: 'Actions',
      'insufficient-permissions':
        'You cannot update this alert because it also includes lines in Agencies for which you do not have permission',
      preview: {
        seeMore: 'See more',
      },
    },
    'alert-template-list': {
      title: 'Alert templates',
      name: 'Name',
      creationDate: 'Creation date',
      modificationDate: 'Last modified',
      createdBy: 'Created by',
      actions: 'Actions',
      create: 'Create a new alert template',
      'copy-modal': {
        error: 'There was an error duplicating the alert template',
        confirm: 'Confirm',
        cancel: 'Cancel',
        question: 'Do you want to duplicate the alert template?',
      },
      'delete-modal': {
        error: 'There was an error deleting the alert template',
        confirm: 'Confirm',
        cancel: 'Cancel',
        question: 'Are you sure you want to delete the alert template?',
      },
    },
    'alert-template-creation': {
      title: 'Alert template creation',
      submit: 'Save template',
      error: {
        EMPTY_NAME: 'Please enter a name for your template',
        INVALID_NAME: 'A template with this name already exists',
        DEFAULT_ERROR: 'There was an error creating your template',
      },
    },
    'alert-template-edition': {
      title: 'Template',
      submit: 'Save changes',
      'use-button': 'Create an alert from this template',
      error: {
        EMPTY_NAME: 'Please enter a name for your template',
        INVALID_NAME: 'A template with this name already exists',
        DEFAULT_ERROR: 'There was an error updating your template',
      },
    },
    'network-creation': {
      title: 'Network information',
      network: {
        label: 'Network name',
        placeholder: 'Network',
        error: {
          required: 'Please select a network',
        },
      },
      submit: 'Create the network',
    },
    'network-list': {
      create: 'Create a new network',
      title: 'My networks',
      name: 'Network',
      creationDate: 'Creation date',
    },
    settings: {
      title: 'Network parameters',
      users: {
        title: 'User management',
        'empty-list': 'No user',
        'first-name': 'First name',
        'last-name': 'Name',
        email: 'Email',
        role: 'Role',
        actions: 'Actions',
        superadmin: 'Super Admin',
        admin: 'Admin',
        operator: 'Operator',
        agencies: 'Agencies',
        'all-agencies': 'All network',
      },
      update: {
        'modal-title': 'Update user',
        'ok-text': 'Update',
      },
      invitations: {
        title: 'Pending invitations',
        create: 'Invite a user',
        'empty-list': 'No user',
        email: 'Email',
        role: 'Role',
        actions: 'Actions',
      },
      invite: {
        'modal-title': 'Invite a new user',
        ok: 'Invite',
      },
      error: {
        'agency-required': 'At least 1 agency must be selected',
        required: 'Email is required',
        'invalid-email': 'The email is not valid',
        ALREADY_EXISTS: 'User is already part of the network',
        ALREADY_INVITED: 'User has already been invited to this network',
        IS_SUPERADMIN: 'User is SuperAdmin, he can already access this network',
      },
    },
  },
  utils: {
    date: {
      today: 'today',
      tomorrow: 'tomorrow',
      yesterday: 'yesterday',
      format: 'DD/MM/YYYY',
      placeholder: 'JJ/MM/AAAA',
    },
    time: {
      format: 'HH:mm',
      placeholder: 'HH:MM',
    },
  },
};

export default enTranslations;
